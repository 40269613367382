import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// hooks
import {usePlayer} from "../player/usePlayer";

// actions
import { podcastPageActions } from "./actions";

// types
import { IPodcastPageState } from "./types";
export type storeStatePodcast = {
  podcast: IPodcastPageState
}

export const usePodcastPage = (slug: string) => {
  const dispatch = useDispatch();

  const { list, play, setPlaylist } = usePlayer();

  const { isFetching, error, data, playlist } = useSelector((state: storeStatePodcast) => state.podcast);

  useEffect(() => {
    dispatch(podcastPageActions.getData(slug));
    dispatch(podcastPageActions.getPlaylistData(slug, 1, true));
  },[dispatch]);

  useEffect(() => {
    if (!!playlist) {
      const tracks = playlist.items.map((item: any) => {
        return {
          ...item.track,
          colorFirst: item.colorFirst,
          colorSecond: item.colorSecond,
          musicSrc: `${item.track.musicSrc}?v=${item.track.id}`
        }
      }, []);
      //  set playlist to player
      !list.length && setPlaylist(tracks);

      //if (!!data && play) {
        // document.documentElement.style.setProperty('--color-player-a', data.colorFirst);
        // document.documentElement.style.setProperty('--color-player-b', data.colorSecond);
      //}
    }
  },[playlist, data]);

  useEffect(() => {
    if (!!data) {
      document.documentElement.style.setProperty('--color-playlist-a', data.colorFirst);
      document.documentElement.style.setProperty('--color-playlist-b', data.colorSecond);
    }
  }, [data]);

  const loadMorePlaylist = (slug: string, page: number, sort: boolean) => {
    dispatch(podcastPageActions.getPlaylistData(slug, page, sort));
  };

  return {
    isFetching,
    error,
    data,
    playlist,
    loadMorePlaylist,
  }
}
