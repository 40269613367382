// core
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// actions
import { episodesActions } from "./actions";

// hooks
import {usePlayer} from "../player/usePlayer";

// types
import { IEpisodesState } from "./types";
export type storeStateEpisodes = {
  episodes: IEpisodesState
}

export const useEpisodesList = () => {
  const dispatch = useDispatch();

  const { list, setPlaylist } = usePlayer();
  const { isFetching, error, data } = useSelector((state: storeStateEpisodes) => state.episodes);

  useEffect(() => {
    if (data === null) {
      dispatch(episodesActions.getData(1));
    }
  },[]);

  useEffect(() => {
    if (data) {
      const tracks = data.items.map((item: any) => {
        return {
          ...item.track,
          colorFirst: item.colorFirst,
          colorSecond: item.colorSecond,
          //musicSrc: `https://test-podcasts-s3.s3.eu-north-1.amazonaws.com/podcast-test/podcast-2_1_min_1.mp3?v=${item.track.id}`
          musicSrc: `${item.track.musicSrc}?v=${item.track.id}`
          //musicSrc: `https://cdn.simplecast.com/audio/69e304f5-e80d-42bb-8f15-6343695e96be/episodes/1d005bd7-dcbf-41c0-bf8c-6f016439b92e/audio/2675a868-c257-457b-9b4a-baa0ee686ddd/default_tc.mp3?v=${item.track.id}`
          //musicSrc: `https://res.cloudinary.com/alick/video/upload/v1502689683/Luis_Fonsi_-_Despacito_ft._Daddy_Yankee_uyvqw9.mp3?v=${item.id}`
        }
      }, []);

      !list.length && setPlaylist(tracks);
    }

  },[data]);

  const loadMore = (page: number) => {
    dispatch(episodesActions.getData(page));
  };

  return {
    isFetching,
    error,
    data,
    loadMore,
  }
}
