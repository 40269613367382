// Core
import React from 'react';
import {Helmet} from "react-helmet";

//components
import PodcastSlider from '../../components/PodcastSlider';
import Grid from '../../components/Grid';

import {MainLayout} from "../../layouts";

const Home: React.FC = () => {

  const homeMetaTags = (
    <>
      <Helmet>
        <title>Parimatch. Играют они – побеждаешь ты!</title>
        <meta name="description" content="Welcome on board! Здесь мы еженедельно говорим про ставки, спорт, CS:GO, Dota2 и многое другое. Слушайте, подписывайтесь. Всем добра и позитива."/>
        <meta property="og:title" content="Париматч подкасты. Здесь говорят про спорт."/>
        <meta property="og:description" content="Welcome on board! Здесь мы еженедельно говорим про ставки, спорт, CS:GO, Dota2 и многое другое. Слушайте, подписывайтесь. Всем добра и позитива."/>
      </Helmet>
    </>
  );

  return (
    <MainLayout>
      {homeMetaTags}
      <PodcastSlider/>
      <Grid/>
    </MainLayout>
  );
};

export default Home;
