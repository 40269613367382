//core
import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// styles
import styles from './styles/styles.module.scss';

// components
import Sort from "../Sort";
import LoadMoreLink from "../LoadMoreLink";
import Track from "./Track";
import {storeStatePodcast} from "../../stores/podcastPage/usePodcastPage";
import {IUserPublicRouteParams} from "./index";
import Error from "../Error";
import Loader from "../Loader";
import {podcastPageActions} from "../../stores/podcastPage/actions";
import {IPodcastPlaylistDataItem} from "../../stores/podcastPage/types";
import { useMemo } from 'react';

const Episodes: React.FC = () => {
  const dispatch = useDispatch();

  const { slug } = useParams<IUserPublicRouteParams>();
  const { isFetching, error, playlist } = useSelector((state: storeStatePodcast) => state.podcast);
  const [tracksList, setTracksList] = useState<IPodcastPlaylistDataItem[] | null>([]);
  const [sorting, setSorting] = useState<boolean>(true);

  //console.log('playlist: ', playlist);

  useEffect(() => {
    if (!!playlist) {
      setTracksList(playlist?.items);
    }
  }, [playlist]);

  const errorMessage = !isFetching && error && <Error message={error.message}/>;

  const loader = isFetching && <Loader/>;

  const handleLoadMore = (): void => {
    const page = playlist ? playlist._meta.currentPage + 1 : 1;
    dispatch(podcastPageActions.getPlaylistData(slug, page, sorting));
  }

  const handleToggleSorting = (sort: boolean) => {
    setSorting(sort);
    dispatch(podcastPageActions.getPlaylistData(slug, 1, sort));
  };

  const loadMoreBtn = (
    <>
      {
        !(playlist?._meta.currentPage === playlist?._meta.pageCount) && !(tracksList?.length === playlist?._meta.totalCount) && <div className={styles.playlist_footer}>
          <LoadMoreLink action={handleLoadMore} label={'Предыдущие выпуски'} />
        </div>
      }
    </>
  );

  const list = !!tracksList?.length && tracksList.map((item: any) => {
    return <Track key={item.id} {...item} />
  })

  const section = (!!tracksList?.length) && (
    <section className={styles.section}>
      <div className={styles.playlist}>
        <div className={styles.playlist_header}>
          <div className={styles.playlist_title}>Эпизоды</div>
          <Sort handler={handleToggleSorting} sort={sorting}/>
        </div>
        <div className={styles.playlist_content}>
          { list }
        </div>
        {loadMoreBtn}
      </div>
    </section>
  );

  return (
    <>
      { errorMessage }
      { section }
      { loader }
    </>
  )
}

export default Episodes;
