export const API = 'https://api.podcasts.pm/v1/';

export const podcastSlider  = `${API}podcast`;
export const podcastPage  = `${API}podcast/get/`;
export const podcastPlaylist  = `${API}podcast/playlist/`;
export const episodePage  = `${API}episode/get/`;
export const episodesList  = `${API}episode?page=`;
export const recommended  = `${API}episode/recommended`;
export const social  = `${API}social`;

