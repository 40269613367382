//core
import React from 'react';
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import Slider from "react-slick";

//components
import RecommendationItem from '../RecommendationItem';

// styles
import styles from './styles/styles.module.scss';
import './styles/slider-theme.scss';
import Error from "../Error";
import Loader from "../Loader";

//hooks
import { useRecommended } from '../../stores/recommended/useRecommended'
import {storeStateEpisode} from "../../stores/episode/useEpisode";
import { IUserPublicRouteParams } from '../Breadcrumb';
import useBreakpoints from '../../_customHooks/useBreakpoint';

const RecommendationBlock: React.FC = () => {
  const routeParams = useParams<IUserPublicRouteParams>();
  const hasEpisode = routeParams.hasOwnProperty('id');

  const point = useBreakpoints();

  const { isFetching, error, data } = useRecommended();

  let episode = useSelector((state: storeStateEpisode) => state.episode);

  const errorMessage = !isFetching && error && <Error message={'error.message'}/>;

  const loader = isFetching && <Loader/>;

  const settingsDesk = {
    accessibility: false,
    arrows: true,
    autoplay: false,
    dots: false,
    focusOnSelect: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    rows: 1,
  };

  const settingsMobile = {
    accessibility: false,
    arrows: true,
    autoplay: false,
    dots: false,
    focusOnSelect: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 4,
  };

  const settings = (point === 'sm') ? {...settingsMobile} : {...settingsDesk}

  const filteredData = hasEpisode ? data?.filter((item) => item.id !== episode?.data?.id) : data;

  // slides
  const slides = filteredData && filteredData.map((item)  => {
    return (
      <div key={item.id} data-id={item.id} className={styles.slide}>
        <RecommendationItem data={item} />
      </div>
    );
  });

  //Slider
  const slider = (
    <Slider className={styles.slider} {...settings}>
      {slides}
    </Slider>
  );

  const recommendation = <div className={styles.recommendation}>
    <div className={styles.title}>Стоит послушать</div>
    <div className={styles.content}>
      {slider}
    </div>
  </div>;

  return (
    <>
      { errorMessage }
      { loader }
      { recommendation }
    </>
  );
};

export default RecommendationBlock;
