// Core
import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//components
import Error from "../Error";
import Loader from "../Loader";
import PodcastItemCard from '../PodcastItemCard';

//hooks
import { usePodcastSlider } from '../../stores/podcastSlider/usePodcastSlider';

//styles
import styles from './styles/styles.module.scss';
import './styles/slider-theme.scss';
import useBreakpoints from "../../_customHooks/useBreakpoint";


const PodcastSlider: React.FC = () => {
  const point = useBreakpoints();

  const { isFetching, error, data } = usePodcastSlider();

  const errorMessage = !isFetching && error && <Error message={'error.message'}/>;

  const loader = isFetching && <Loader/>;

  const settingsDesktop = {
    accessibility: false,
    arrows: true,
    autoplay: false,
    dots: false,
    focusOnSelect: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    rows: 1,
  };
  const settingsMobile = {
    accessibility: false,
    arrows: true,
    autoplay: false,
    dots: false,
    focusOnSelect: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 4,
  };

  const settings = (point === 'sm') ? {...settingsMobile} : {...settingsDesktop};

  // slides
  const slides = data && data.map((item, index)  => {
    return (
      <div key={item.id} className={styles.slide}>
        <PodcastItemCard data={item} />
      </div>
    );
  });

  //Slider
  const sliderMobCls = data && (point === 'sm') && (data.length <= 4) && `${styles.slider__justify_mob}`;
  const slider = data && (
    <Slider className={`${styles.slider} ${!(data.length >= 8) && styles.slider__justify } ${sliderMobCls}`} {...settings}>
      {slides}
    </Slider>
  );

  const sectionCls = data && `${!(data?.length >= 8) && styles.section__full} ${!(data?.length >= 4) && styles.section__full_mob}`;

  return (
    <section className={`${styles.section} ${sectionCls}`}>
      { errorMessage }
      { loader }
      { slider }
    </section>
  )
};

export default PodcastSlider;
