//core
import { Dispatch } from 'redux';

// api
import { get } from '../../API';
import { episodesList } from '../API';

// Types
import {IEpisodesData, types} from './types';
import {playerActions} from "../player/actions";

export const episodesActions = Object.freeze({

  // Sync
  setData: (payload: IEpisodesData) => {
    return {
      type: types.EPISODES_SET_DATA,
      payload
    }
  },

  updateData: (payload: IEpisodesData) => {
    return {
      type: types.EPISODES_UPDATE_DATA,
      payload
    }
  },

  startFetching: () => {
    return {
      type: types.EPISODES_START_FETCHING
    }
  },

  stopFetching: () => {
    return {
      type: types.EPISODES_STOP_FETCHING,
    }
  },

  setFetchingError: (error: any) => {
    return {
      type: types.EPISODES_SET_FETCHING_ERROR,
      error: true,
      payload: error,
    }
  },

  // Async Episodes Page Data
  getData: (page: number) => async (dispatch: Dispatch) => {
    dispatch({
      type: types.EPISODES_FETCH
    });

    dispatch(episodesActions.startFetching());

    try {
      const response: any = await get(`${episodesList}${page || 1}`);


      if (response.status === 200) {
        const results = await response.json();

        if (results._meta.currentPage > 1) {
          dispatch(episodesActions.updateData(results));

          const tracks = results.items.map((item: any) => {
            return {
              ...item.track,
              colorFirst: item.colorFirst,
              colorSecond: item.colorSecond,
              //musicSrc: `https://test-podcasts-s3.s3.eu-north-1.amazonaws.com/podcast-test/podcast-2_1_min_1.mp3?v=${item.track.id}`
              musicSrc: `${item.track.musicSrc}?v=${item.track.id}`
              //musicSrc: `https://res.cloudinary.com/alick/video/upload/v1502689683/Luis_Fonsi_-_Despacito_ft._Daddy_Yankee_uyvqw9.mp3?v=${item.id}`
              //musicSrc: `https://cdn.simplecast.com/audio/69e304f5-e80d-42bb-8f15-6343695e96be/episodes/1d005bd7-dcbf-41c0-bf8c-6f016439b92e/audio/2675a868-c257-457b-9b4a-baa0ee686ddd/default_tc.mp3?v=${item.track.id}`
            }
          }, []);

          dispatch(playerActions.updatePlaylistData(tracks));
        } else {
          dispatch(episodesActions.setData(results));
          //
          // // update
          // const tracks = results.items.map((item: any) => {
          //   return {
          //     ...item.track,
          //     musicSrc: `${item.track.musicSrc}?v=${item.track.id}`
          //   }
          // }, []);

          //dispatch(playerActions.setPlaylistData(tracks));
        }
      } else {
        const error = {
          status: response.status
        };
        dispatch(episodesActions.setFetchingError(error));
      }
    } catch (error) {
      dispatch(episodesActions.setFetchingError(error));
    }

    dispatch(episodesActions.stopFetching());
  }
});
